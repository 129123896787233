import React from "react"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import ContactContent from "../components/contact/contactContent"

const title = `Kontakt`

const ContactPage = () => (
  <Layout title={title} >
    <SEO title={title} pathname="/kontakt" pageType="ContactPage" />
    <ContactContent />
  </Layout>
)

export default ContactPage
