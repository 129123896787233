import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import config from "../../../data/SiteConfig"
import ResponsiveImage from "../common/responsiveImage"
import ResponsiveImageText from "../common/responsiveImageText"
import BabyBedSquare from "../images/babyBedSquare"
import BabyBellySquare from "../images/babyBellySquare"
import Signature from "../images/signature"
import styles from './contactContent.module.css'

export default function ContactContent() {
  const data = useStaticQuery(graphql`
    query {
      intro: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(contact/1_intro.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  return (
    <div>
      <ResponsiveImageText
        image={
          <ResponsiveImage
            portrait={<BabyBellySquare className={`stretchWide`} />}
            landscape={<BabyBellySquare />}
            breakpoint={config.layoutBreakpointDesktop}
          />
        }
      >
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: data.intro.edges[0].node.html,
            }}
          />
          <div className={styles.signatureWrapper}>
            <Signature />
          </div>
        </div>
      </ResponsiveImageText>
      {/* <ContactOptions /> */}
    </div>
  )
}
